<template>
  <div class="goods" id="goods" name="goods">
    <!-- 面包屑 -->
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>全部商品</el-breadcrumb-item>
        <el-breadcrumb-item v-if="search">搜索</el-breadcrumb-item>
        <el-breadcrumb-item v-else>分类</el-breadcrumb-item>
        <el-breadcrumb-item v-if="search">{{ search }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 面包屑END -->

    <!-- 分类标签 -->
    <div class="nav">
      <div class="product-nav">
        <div class="title">商品分类</div>
        <el-radio-group v-model="twoClassCode">
          <el-radio
            border
            size="medium"
            label="99999"
            @click.native.prevent="clickCategory('99999')"
            >全部
          </el-radio>
          <el-radio
            border
            size="medium"
            v-for="item in categoryList"
            :key="item.twoClassCode"
            :label="item.twoClassCode"
            @click.native.prevent="clickCategory(item.twoClassCode)"
            >{{ item.twoClassName }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="product-nav">
        <div class="title">使用场景</div>
        <div class="item">
          <el-radio-group v-model="selectedSort" v-if="selectedSort != -1">
            <el-radio
              v-for="item in homeCategoryList"
              border
              :key="item.selectedSort"
              :label="item.selectedSort"
              @click.native.prevent="clickSort(item.selectedSort)"
              >{{ item.labelName }}
            </el-radio>
          </el-radio-group>
          <el-radio-group v-model="brandCode" v-else>
            <el-radio
              label="99999"
              border
              size="medium"
              v-if="brandList.length === 0"
              @click.native.prevent="clickBrand('99999')"
              >全部
            </el-radio>
            <el-radio
              v-else
              v-for="item in brandList"
              border
              :key="item.brandCode"
              :label="item.brandCode"
              @click.native.prevent="clickBrand(item.brandCode)"
              >{{ item.brandName }}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <!-- 分类标签END -->

    <!-- 主要内容区 -->
    <div class="main">
      <div class="list">
        <MyList :list="product" v-if="product.length > 0"></MyList>
        <div v-else class="none-product">
          <svg
            t="1683795562570"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="5839"
            width="200"
            height="200"
          >
            <path
              d="M64 409l227.038-152.906A24 24 0 0 1 304.444 252h417.194a24 24 0 0 1 13.492 4.151L960 409v339c0 13.255-10.745 24-24 24H88c-13.255 0-24-10.745-24-24V409z"
              fill="#9F9F9F"
              fill-opacity=".5"
              p-id="5840"
            ></path>
            <path
              d="M64 409h283.136c13.255 0 24 10.745 24 24v44.68c0 13.254 10.745 24 24 24h233.136c13.255 0 24-10.746 24-24V433c0-13.255 10.745-24 24-24H960v355a8 8 0 0 1-8 8H72a8 8 0 0 1-8-8V409z"
              fill="#FFFFFF"
              fill-opacity=".4"
              p-id="5841"
            ></path>
          </svg>
          <p>抱歉没有找到相关的商品，请看看其他的商品<a href="/">返回首页</a></p>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagination" v-show="selectedSort == -1">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :total="total"
          @current-change="currentChange"
        ></el-pagination>
      </div>
      <!-- 分页END -->
    </div>
    <!-- 主要内容区END -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      categoryList: [], //分类列表
      homeCategoryList: [], //首页全部分类
      brandList: [], //使用场景列表
      categoryID: [], // 分类id
      product: "", // 商品列表
      productList: "",
      total: 0, // 商品总量
      pageSize: 15, // 每页显示的商品数量
      currentPage: 1, //当前页码
      activeName: "-1", // 分类列表当前选中的id
      search: "", // 搜索条件
      twoClassCode: "99999", //商品分类绑定值
      brandCode: "99999", //使用场景绑定值
      selectedSort: -1, //首页进入的商品场景
      goodsTimer: null,
      searchTimer: null,
    };
  },
  created() {
    // 获取分类列表
    this.getCategory();
    this.getscene()
    let { search, selectedSort } = this.$route.query;
    if (!search && !selectedSort) {
      this.getGoodsData();
    }
  },
  activated() {
    this.backtop();
    if (this.$route.query.search != undefined) {
      this.search = this.$route.query.search;
    }
  },
  watch: {
    // 监听搜索条件，响应相应的商品
    search(val) {
      if (val != "") {
        this.getProductBySearch(val);
      }
    },
    // 监听路由变化，更新路由传递了搜索条件
    $route(to, from) {
      let { selectedSort, search } = to.query;
      if (search) {
        this.brandList = [];
        this.search = search;
      }
      if (to.path == "/goods" && from.path == "/") {
        if (selectedSort) {
          this.twoClassCode = "99999";
          this.clickSort(Number(selectedSort));
        }
      }
    },
  },
  methods: {
    clickCategory(twoClassCode) {
      if (this.twoClassCode != twoClassCode) {
        this.twoClassCode = twoClassCode;
        this.brandList = [];
        this.search = "";
        this.selectedSort = -1;
        this.brandCode = "99999";
        this.currentPage = 1;
        this.total = 0;
        if (twoClassCode == "99999") {
          this.brandList = [];
        } else {
          let category = this.categoryList.find(
            (item) => item.twoClassCode == twoClassCode
          );
          this.brandList = category.brandVoList;
        }
        this.getGoodsData();
      }
    },
    clickBrand(brandCode) {
      if (this.brandCode != brandCode) {
        this.brandCode = brandCode;
        this.getGoodsData();
      }
    },
    clickSort(selectedSort) {
      if (this.selectedSort != selectedSort) {
        this.selectedSort = selectedSort;
        let list = this.homeCategoryList.find(
          (item) => item.selectedSort == selectedSort
        ).apiCommodityListPageVos;
        this.product = list;
      }
    },
    // 返回顶部
    backtop() {
      const timer = setInterval(function () {
        const top =
          document.documentElement.scrollTop || document.body.scrollTop;
        const speed = Math.floor(-top / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          top + speed;

        if (top === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
    // 页码变化调用currentChange方法
    currentChange(currentPage) {
      this.currentPage = currentPage;
      if (this.search != "") {
        this.getProductBySearch();
      } else {
        this.getGoodsData();
      }
      this.backtop();
    },
    // 向后端请求分类列表数据
    getCategory() {
      let { selectedSort } = this.$route.query;
      if (selectedSort) {
        this.$axios
          .get("/zuwu-api/official/index?applicationType=3")
          .then(({ data }) => {
            let list = data.data.apiCommodityLabelListVo.commodityLabelList;
            this.homeCategoryList = list;
            this.clickSort(Number(selectedSort));
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      
      this.$axios
        .get("/zuwu-api/official/commodityBrandList", {})
        .then(({ data }) => {
          let list = data.data.oneClassVoList[0].twoClassVoList;
          this.categoryList = list;
          this.twoClassCode = "99999";
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // 拿使用惨景数据
    getscene(){
      this.$axios
          .get("/zuwu-api/official/index?applicationType=3")
          .then(({ data }) => {
            let list = data.data.apiCommodityLabelListVo.commodityLabelList;
            this.homeCategoryList = list;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 向后端请求全部商品或分类商品数据
    getGoodsData() {
      if (this.goodsTimer) {
        clearTimeout(this.goodsTimer);
      }
      this.goodsTimer = setTimeout(() => {
        let params = {
          brandCode: this.brandCode,
          current: this.currentPage,
          size: this.pageSize,
        };
        if (this.twoClassCode != "99999") {
          params.twoClassCode = this.twoClassCode;
        }
        // 如果分类列表为空则请求全部商品数据，否则请求分类商品数据
        this.$axios
          .get("/zuwu-api/official/commodityClassBrandList", {
            params,
          })
          .then(({ data }) => {
            let list = data.data.records;
            this.total = data.data.total;
            this.product = list;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      });
    },
    // 通过搜索条件向后端请求商品数据
    getProductBySearch() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(() => {
        this.currentPage = 1;
        this.total = 0;
        this.selectedSort = -1;
        this.brandCode = "99999";
        this.twoClassCode = "99999";
        this.$axios
          .get("/zuwu-api/official/page", {
            params: {
              title: this.search,
              current: this.currentPage,
              size: this.pageSize,
            },
          })
          .then(({ data }) => {
            let list = data.data.records;
            this.total = data.data.total;
            this.product = list;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      });
    },
  },
};
</script>

<style scoped>
@import "../assets/css/index.css";
</style>